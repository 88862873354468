import { createApp } from 'vue'
import App from './App.vue'
import store from '@/framework/store'
import i18n from '@/framework/i18n'
import createAppRouter from '@/framework/router'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import '@/assets/iconfont'
// import Vue3DraggableResizable from 'vue3-draggable-resizable'
//default styles
// import 'vue3-draggable-resizable/dist/Vue3DraggableResizable.css'

import vdr from 'vue-draggable-resizable-gorkys/src/components/vue-draggable-resizable.vue'
import 'vue-draggable-resizable-gorkys/dist/VueDraggableResizable.css'
import Vue3ColorPicker from "vue3-colorpicker";
import "vue3-colorpicker/style.css";
window.VUE_APP_BASE_WS="wss://saas.enewie.com/captions/"
window.url = '/api'
window.VUE_APP_SUBTITLE_WSS="wss://lm.enewie.com/"
// document.title="智能翻译投屏系统"
// window.VUE_APP_SUBTITLE_WSS="ws://localhost:81/"
createApp(App).use(createAppRouter()).use(i18n).use(store).use(Antd).use(Vue3ColorPicker).component("vue-draggable-resizable",vdr).mount('#app');
