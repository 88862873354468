import { defineStore } from 'pinia'

export const useLocaleStore = defineStore('locale', {
    state: () => ({ locale:null }),
    actions: {
        setLocale(locale) {
            this.locale = locale;
            if(locale) {
                localStorage.setItem('lang', locale);
                document.querySelector('html').setAttribute('lang', locale);
            } else {
                localStorage.removeItem('lang');
                document.querySelector('html').removeAttribute('lang');
            }
        }
    }
})
