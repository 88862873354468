import { createI18n, useI18n as _useI18n } from 'vue-i18n';
import appConfig from '../config'
import { useLocaleStore } from '../store/localeStore'
import store from '../store'

const localeStore = useLocaleStore(store);

const loadedLocale = {}
export const getLanguage = (guessOnly=false) => {
    // 是否设置过国际化
    const language = (guessOnly ? null : (localeStore.locale ?? localStorage.getItem('lang'))) ?? navigator.language?.toLocaleLowerCase()?.split('-')?.[0];
    // (有支持设置)设置的国际化是否在支持范围内
    if(appConfig.locale?.available?.length??0>0) {
        // 有国际化支持
        return appConfig.locale?.available?.includes(language) ? language : (appConfig.locale.main ?? 'zh')
    }
    // 没有任何设置采用默认值
    return appConfig.locale.main ?? language;
}

// 是否有国际化支持
export const isUseI18n = () => (appConfig.locale.available?.length??0) > 0

// 加载国际化文件
const loadLocaleMessages = (locale) => {
    let r = loadedLocale[locale];
    // 是否有国际化的配置文件
    if(!r) {
        r = require(`./${locale}.js`).default;
        // 是否有国际化支持
        if(isUseI18n()) {
            // 将文件添加到r.messages
            r.messages = Object.assign(r.messages, require(`@/i18n/${locale}.json`))
        }
        loadedLocale[locale] = r;
    }
    return r;
}

// 设置国际化语种
export const setLanguage = (locale) => {
    const lang = locale ?? getLanguage(true);
    // 加载国际化文件
    const msg = loadLocaleMessages(lang);
    if(i18n) {
        // 如果i18n配置文件中不包含所选国际化文件则添加
        if(!i18n.global.availableLocales.includes(lang)) {
            i18n.global.setLocaleMessage(lang, msg.messages)
        }
        if (i18n.mode === 'legacy') {
            i18n.global.locale = lang
        } else {
            i18n.global.locale.value = lang
        }
    }
    localeStore.setLocale(locale);
}

// Antd国际化
export const getAntdLocale = (locale) => {
    // 获得语种配置
    if(!locale) locale = localeStore.locale ?? getLanguage();
    // 确认国际化是否支持, 如果不支持则弹出提示
    if(!loadedLocale[locale]) {
        console.warn(`locale [${locale}] not loaded. please chech your locale`);
    }
    return loadedLocale[locale]?.antdLocale;
}

const create = () => {
    // 获取当前国际化的语种
    const lang = getLanguage();
    // 设置当前语种选择
    localeStore.locale = lang;

    const messages = {};
    // 加载相应的配置文件
    messages[lang] = loadLocaleMessages(lang).messages;
    // 有国际化支持就初始化
    return !isUseI18n() ? null : createI18n({
        legacy: false,
        locale: lang, // 首先从缓存里拿，没有的话就用浏览器语言，
        fallbackLocale: appConfig.locale.main, // 设置备用语言
        messages,
    });
}

export const useI18n = _useI18n;


const i18n = create();
export default i18n
