import {createRouter, createWebHistory} from 'vue-router'
import routes from '@/routes.js'
// import { permission } from './permission'
// import appConfig from "../config";
//
// const joinUrl =  (base,sub) => {
//     if(base==null || sub==null) return base ?? sub ?? '';
//     const bes = base.endsWith('/');
//     const sss = sub.startsWith('/');
//     if(bes && sss) return base+sub.substring(1);
//     if(!bes && !sss) return base+'/'+sub;
//     return base+sub;
// }
// const generateMenu = (router, _purl=null) => {
//     if(router==null) return undefined;
//     return router.reduce((p,item)=>{
//         const url = joinUrl(_purl,item.path);
//         const children = generateMenu(item.children, url);
//         if(item.menu) {
//             p.push({ name:item.menu, icon:item.icon, url, children });
//         } else if(children) {
//             p = p.concat(children);
//         }
//         return p;
//     },[])
// }

const createAppRouter = () => {
    // const routerList = router.options.routes;

    // appConfig.menu = generateMenu(routerList)

    // permission(router);

    return createRouter({
        history: createWebHistory('/'),
        routes: routes
    });
}

export default createAppRouter