
const routes = [
    {
        path: '/',
        name: 'HomeView',
        component: () => import('@/views/HomeViews/HomeView.vue')
    },
    // {
    //     path: '/record',
    //     name: 'RecordView',
    //     component: () => import('@/views/record/RecordView.vue')
    // },
    {
        path: '/share/:uCode',
        component: () => import('@/views/events/layout-page'),
    },
    {
        path: '/subscr',
        component: () => import('@/framework/layout/layout'),
    },
]

export default routes
